import { type FC } from 'react'

import getServerSidePropsHandler from '@redux/features/homePage/getServerSideProps'

import { CUSTOMER_SUPPORT_LINK } from '@hstConstants/links/support'
import type { MainPageProps } from '@pages/MainPage'
import MainPage from '@pages/MainPage'

export type Props = Omit<MainPageProps, 'customerSupportLink' | 'noIndex'>

const RootPage: FC<Props> = props => <MainPage {...props} customerSupportLink={CUSTOMER_SUPPORT_LINK} />

export const getServerSideProps = getServerSidePropsHandler

export default RootPage
